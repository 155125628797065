import lazyWithPreload from 'config/lazy-with-preloading';
import { useEffect } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

const Login = lazyWithPreload(() => import('./pages/Login/Login'));

const AuthRouter = () => {
    useEffect(() => {
        Login.preload();
    }, []);

    const routes = useRoutes([
        {
            path: '/',
            element: <Login />,
        },

        {
            path: '*',
            element: <Navigate replace to="/404" />,
        },
    ]);

    return routes;
};

export default AuthRouter;
